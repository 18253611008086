/*Header*/
.pass-plan_header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-background-surface-header);
    padding: var(--spacing-2);
    font-size: var(--font-heading-5-font-size);
    box-shadow: var(--list-panel-box-shadow);
    z-index: 90;
  }

.pass-plan_header-step {
  margin-left: 8px;
}

  .pass-plan_header-execute {
    margin-right: 12px;
  }

  .pass-plan_header-instruction.with-margin {
    margin-right: 5px;
  }
  
/*RuxTree*/
.pass-plan_tree-wrapper rux-tree-node::part(node) {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-inline-end: var(--spacing-3);
    padding-block: var(--spacing-2, 0.5rem);
    background-color: var(--color-background-surface-header);
    box-shadow: 0 -1px 0 0 var(--color-background-base-default, #101923) inset;
  }
  
  .rux-tree.pass-plan_tree-wrapper rux-tree-node::part(text) {
    padding-inline-start: var(--spacing-6);
    width: 100%;
    padding-block: var(--spacing-1);
  }

  .pass-plan_executable-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .step-id {
    margin-left: -25px;
  }

  .play-button {
    margin-right: 5px;
  }


/* Styles to fix row padding */
  .rux-tree.pass-plan_tree-wrapper rux-tree-node[aria-level="2"] {
    padding-block-start: var(--spacing-1);
  }
  
  .rux-tree.pass-plan_tree-wrapper rux-tree-node:first-child {
    padding-block-start: var(--spacing-1);
  }
  
  .rux-tree.pass-plan_tree-wrapper rux-tree-node:last-child:not([aria-level="2"]) {
    padding-block-end: var(--spacing-1);
  }
  
 /* Pass Plan Footer */
  .pass-plan div[slot="footer"] {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  /* Ensure that the button doesn't grow */
  .pass-plan_command-progress RuxButton {
    flex: 0 0 auto;
    background-color: inherit; /* Lighter background color on hover */
  }
  
  /* Update this rule to apply the background color on hover to the entire row */
  .pass-plan_command-progress:hover {
    background-color: #f0f0f0; 
  }

  .pass-plan_number-wrapper {
    min-width: 110px;
    max-width: 100px
  }

   .pass-plan_mnemonic-wrapper {
    width: 175px
  }